import React, { useState, useEffect } from 'react'
import Parser from 'html-react-parser'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import bullet from '../assets/bullet.svg'
import { motion } from 'framer-motion'

// const base_url = 'https://kozpark.herokuapp.com'

const Hir = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/hirek?filters[Slug]=${id}&populate=*`
        )
        setData(response.data.data[0])
      } catch (error) {
        console.log(error)
      }

      setIsLoading(false)
    }

    fetchData()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!isLoading) {
      let marks = document.getElementsByTagName('mark')
      let marksArray = [...marks]
      if (marksArray.length > 0) {
        marksArray.forEach((item) => {
          if (item.parentNode.nodeName === 'SPAN') {
            item.parentNode.parentNode.style.backgroundColor =
              'var(--box-color)'
            item.parentNode.parentNode.style.borderRadius = '56px'
            item.parentNode.parentNode.style.padding = '5%'
          } else if (item.parentNode.nodeName === 'P') {
            item.parentNode.style.backgroundColor = 'var(--box-color)'
            item.parentNode.style.borderRadius = '56px'
            item.parentNode.style.padding = '5%'
          }

          item.style.backgroundColor = 'transparent'
          item.style.color = 'var(--primary-bg)'
          item.style.fontStyle = 'italic'
        })
      }

      let smallTexts = document.getElementsByClassName('text-small')
      let smallTextsArray = [...smallTexts]
      if (smallTextsArray.length > 0) {
        smallTextsArray.forEach((item) => {
          item.parentNode.style.lineHeight =
            'calc(var(--paragraph-font-size) + 5px)'
        })
      }
    }
  }, [isLoading])

  return (
    <Wrapper>
      <div className='hir-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            <div className='hir-kep-tarolo'>
              <img
                src={data.attributes.Kep.data.attributes.url}
                alt={data.attributes.Kep.data.attributes.alternativeText}
              />
            </div>
            <div className='hir-tartalom-tarolo'>
              <p className='hir-datum'>
                {new Date(data.attributes.publishedAt).toLocaleDateString(
                  'hu-HU'
                )}
              </p>
              <h1 className='hir-cim'>{data.attributes.Cim}</h1>
              <div className='hir-tartalom'>
                {Parser(data.attributes.Tartalom)}
              </div>
              <div className='vissza-a-cikkekhez-fo-tarolo'>
                <a className='vissza-a-cikkekhez-tarolo' href='/hirek'>
                  <div className='vissza-a-cikkekhez-gomb'>
                    Vissza a cikkekhez
                  </div>
                </a>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .hir-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    min-height: calc(100vh - 150px);
    //margin-top: 2.5rem;
    margin-top: 150px; // Azert ennyi, mert a main-bol ki lett torolve a 150px, itt kapja meg ezt a beallitast.
    .loading {
      min-height: 100vh;
    }
    .hir-kep-tarolo {
      width: 100%;
    }
    .hir-datum {
      font-size: var(--small-text-font-size);
      color: #989898;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .hir-kep-tarolo img {
      width: 100%;
      height: 20rem;
      object-fit: cover;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
    .hir-tartalom-tarolo {
      padding-left: 15%;
      padding-right: 15%;
    }
    .hir-cim {
      margin-bottom: 2%;
      font-size: var(--main-title-font-size);
      line-height: calc(var(--main-title-font-size) + 5px);
      font-weight: 800;
    }
    .hir-tartalom p {
      font-size: var(--paragraph-font-size);
    }
    .hir-tartalom {
      font-size: var(--paragraph-font-size);

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 40px;
      }

      ul {
        list-style: none;
      }
      ul li:before {
        content: url(${bullet});
        position: relative;
        top: 6px;
        margin-right: 33px;
      }

      ul li {
        margin-top: 17px;
        margin-bottom: 17px;
        font-weight: 700;
      }

      ol {
        list-style: none !important;
        counter-reset: li;
        margin-left: 45px;
      }

      ol li::before {
        content: counter(li) '.';
        color: var(--box-color);
        font-size: var(--title-font-size);
        display: inline-block;
        width: 2em;
        margin-left: -2.5em;
        margin-right: 38px;
        text-align: right;
        font-weight: 800;
      }

      ol li {
        counter-increment: li;
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
    .vissza-a-cikkekhez-tarolo {
      background-color: var(--box-color);
      width: 40%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-top: 7rem;
      transition: all 0.3s;
    }
    .vissza-a-cikkekhez-tarolo:hover {
      background-color: var(--box-color-hover);
    }
    .vissza-a-cikkekhez-gomb {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
    .text-small {
      font-size: var(--small-text-font-size);
    }
    .vissza-a-cikkekhez-fo-tarolo {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1024px) {
    .hir-fo-tarolo {
      width: 100%;
      margin-top: 0;

      .hir-kep-tarolo {
        img {
          height: 13rem;
          object-fit: cover;
          width: 100%;
          border-radius: 0;
        }
      }

      .hir-tartalom-tarolo {
        width: 94%;
        margin: auto;
        padding: 0;
      }

      img {
        width: 100%;
        height: 13rem;
        object-fit: cover;
        border-radius: 0;
      }

      .hir-cim {
        font-size: var(--title-font-size);
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }

      .vissza-a-cikkekhez-tarolo {
        width: 80%;
        margin: 3rem 0;
        padding: 1rem 0;
      }
    }
  }
`

export default Hir
