import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { GlobalContext } from '../helper/GlobalContext'
// import { base_url } from '../helper/urls'
import axios from 'axios'
import { motion } from 'framer-motion'
import close from '../assets/icons/close.png'
const base_url = 'https://kozpark.herokuapp.com'

const Popup = () => {
  const { openedPopup, setOpenedPopup } = useContext(GlobalContext)
  const [popup, setPopup] = useState(true)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const isMobile = window.innerWidth < 1024

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/popups?sort=publishedAt%3Adesc&populate=*`
        )
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
    //eslint-disable-next-line
  }, [])

  return (
    data.length > 0 &&
    data[0].attributes.Megjelenes &&
    popup &&
    !openedPopup && (
      <Wrapper>
        {!isMobile && data[0].attributes.Asztali_nezet_kep.data ? (
          <motion.div
            className='desktop-popup-container'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: 'easeInOut',
              duration: 1,
              delay: 0.5,
            }}
            exit={{ opacity: 0 }}>
            <div
              className='close-popup'
              onClick={() => {
                setPopup(false)
                setOpenedPopup(true)
              }}>
              <img src={close} alt='close icon' className='close-icons'></img>
            </div>
            <img
              src={
                data.length > 0 &&
                data[0].attributes.Asztali_nezet_kep.data.attributes.url
              }
              alt='popup img'
            />
          </motion.div>
        ) : (
          <>
            {data[0].attributes.Mobil_nezet_kep.data ? (
              <motion.div
                className='mobile-popup-container'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1,
                  delay: 0.5,
                }}
                exit={{ opacity: 0 }}>
                <div className='close-popup' onClick={() => setPopup(false)}>
                  <img src={close} alt='close icon' className='close-icons' />
                </div>
                <img
                  src={data[0].attributes.Mobil_nezet_kep.data.attributes.url}
                  alt='popup img'
                />
              </motion.div>
            ) : (
              <div>{setPopup(false)}</div>
            )}
          </>
        )}
      </Wrapper>
    )
  )
}

const Wrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 150;
  background-color: rgba(0, 0, 0, 0.45);

  .desktop-popup-container {
    position: relative;
    margin-top: 150px;
    width: 60vw;
    height: 65vh;
    box-shadow: 8px 6px 12px rgba(0, 0, 0, 0.45);

    .close-popup {
      position: absolute;
      top: 20px;
      right: 20px;

      img {
        width: 30px;
        height: 30px;

        :hover {
          cursor: pointer;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .mobile-popup-container {
    position: relative;
    margin-top: 100px;
    width: 90%;
    height: 80vh;

    .close-popup {
      position: absolute;
      top: 20px;
      right: 20px;

      img {
        width: 30px;
        height: 30px;

        :hover {
          cursor: pointer;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export default Popup
