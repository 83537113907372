import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import { motion } from 'framer-motion'
import Parser from 'html-react-parser'

// const base_url = 'https://kozpark.herokuapp.com'

const Allas = () => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/allasaink/${id}?populate=*`
        )
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
    //eslint-disable-next-line
  }, [])
  return (
    <Wrapper>
      <div className='allas-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            <h1 className='allas-cim'>{Parser(data.attributes.Cim)}</h1>
            <p className='allas-tartalom'>{Parser(data.attributes.Tartalom)}</p>
            <a className='felhivasok-tarolo' href='/felhivasok'>
              <div className='felhivasok-gomb'>Vissza az állásokhoz</div>
            </a>
          </motion.div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background-color: var(--light-grey);
  .allas-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    min-height: calc(100vh - 150px);
    margin-top: 250px;
    .loading {
      min-height: 100vh;
    }
    .allas-cim {
      margin-bottom: 2%;
      font-size: var(--main-title-font-size);
      font-weight: 800;
    }
    .allas-tartalom {
      font-size: var(--paragraph-font-size);
    }
    .felhivasok-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-bottom: 5%;
      transition: all 0.3s;
    }
    .felhivasok-tarolo:hover {
      background-color: var(--box-color-hover);
    }
    .felhivasok-gomb {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1024px) {
    .allas-fo-tarolo {
      width: 94%;
      text-align: center;
      margin-top: 2.5rem;

      .allas-cim {
        font-size: var(--title-font-size);
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .allas-tartalom {
        font-style: italic;
        padding: 3.5rem 1rem 2.5rem 0;
      }

      .felhivasok-tarolo {
        width: 80%;
        margin: 1rem auto;
        padding: 1rem 0;
      }
    }
  }
`

export default Allas
