import React, { useState, useEffect } from 'react'
import Parser from 'html-react-parser'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import { motion } from 'framer-motion'

// const base_url = 'https://kozpark.herokuapp.com'

const Hirek = () => {
  const [data, setData] = useState([])
  const [newData, setNewData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [noMoreData, setNoMoreData] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/hirek?sort=publishedAt%3Adesc&pagination[pageSize]=3&populate=*`
        )
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${base_url}/api/hirek?sort=publishedAt%3Adesc&pagination[pageSize]=3&pagination[page]=${currentPage}&populate=*`
        )
        setNewData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      try {
        const response = await axios.get(
          `${base_url}/api/hirek?sort=publishedAt%3Adesc&pagination[pageSize]=3&pagination[page]=${
            currentPage + 1
          }&populate=*`
        )
        if (response.data.data.length === 0) {
          setNoMoreData(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    if (currentPage !== 1) {
      fetchData()
    }
  }, [currentPage])

  useEffect(() => {
    if (currentPage !== 1) {
      setData((oldArray) => [...oldArray, ...newData])
    }
    //eslint-disable-next-line
  }, [newData])

  return (
    <Wrapper>
      <div className='hirek-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            {data.map(function (item, i) {
              return (
                <a href={'/hirek/' + item.attributes.Slug} key={i}>
                  <div className='hir-tarolo'>
                    <div className='hir-kep-tarolo'>
                      <img
                        src={item.attributes.Kep.data.attributes.url}
                        alt={
                          item.attributes.Kep.data.attributes.alternativeText
                        }
                      />
                    </div>
                    <div className='hir-szoveg-tarolo'>
                      <h2>{item.attributes.Cim}</h2>
                      <div className='hir-tartalom'>
                        {Parser(
                          `${item.attributes.Kivonat.substring(0, 180)}...`
                        )}
                      </div>
                      <p className='hir-datum'>
                        {new Date(
                          item.attributes.publishedAt
                        ).toLocaleDateString('hu-HU')}
                      </p>
                    </div>
                  </div>
                </a>
              )
            })}
          </motion.div>
        )}
        {noMoreData ? (
          <div className='tovabbi-hirek-betoltese-tarolo'>
            <p>Nincsenek további hírek.</p>
          </div>
        ) : (
          <div className='tovabbi-hirek-betoltese-tarolo'>
            <div
              className='tovabbi-hirek-betoltese'
              onClick={() => setCurrentPage(currentPage + 1)}>
              További hírek betöltése
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .hirek-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    margin-top: 250px;
    .loading {
      min-height: 100vh;
    }
    .hir-tarolo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 7%;
      margin-bottom: 7%;
    }
    .hir-tarolo:hover {
      border-radius: 35px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .hir-kep-tarolo {
      width: 35%;
      display: flex;
      justify-content: center;
    }
    .hir-kep-tarolo img {
      width: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
    .hir-szoveg-tarolo {
      width: 65%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80%;
      padding-left: 7%;
      padding-top: 3%;
      padding-bottom: 3%;
      padding-right: 3%;
      h2 {
        font-size: var(--title-font-size);
      }
      .hir-tartalom {
        margin-top: 3%;
        margin-bottom: 3%;

        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        color: #000;
        font-size: var(--paragraph-font-size);
      }
      .hir-tartalom p {
        font-size: var(--paragraph-font-size);
      }
      .hir-datum {
        font-size: var(--small-text-font-size);
        color: #989898;
      }
    }
    .tovabbi-hirek-betoltese-tarolo {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .tovabbi-hirek-betoltese {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
      transition: all 0.3s;
    }
    .tovabbi-hirek-betoltese:hover {
      cursor: pointer;
      background-color: var(--box-color-hover);
    }
    .tovabbi-hirek-betoltese-tarolo p {
      font-size: var(--title-font-size);
    }
  }

  @media screen and (max-width: 1024px) {
    .hirek-fo-tarolo {
      width: 94%;
      margin-top: 0;

      .hir-tarolo {
        flex-direction: column;
      }

      .hir-kep-tarolo {
        width: 100%;
      }

      .hir-szoveg-tarolo {
        width: 100%;
        padding: 3%;
      }

      .hir-cim {
        font-size: var(--title-font-size);
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }

      .vissza-a-cikkekhez-tarolo {
        width: 80%;
        margin: 3rem 0;
        padding: 1rem 0;
      }

      .tovabbi-hirek-betoltese-tarolo {
        width: 94%;
        margin: auto;

        p {
          font-size: calc(var(--title-font-size) - 0.75rem);
        }
      }

      .tovabbi-hirek-betoltese {
        width: 100%;
        margin: 3rem 0;
        padding: 1rem 0;
      }
    }
  }
`

export default Hirek
