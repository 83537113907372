import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Parser from 'html-react-parser'
import { base_url } from '../helper/urls'
import axios from 'axios'
import circle from '../assets/orange-circle.svg'
import arrowLeft from '../assets/arrow-left.svg'
import arrowRight from '../assets/arrow-right.svg'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

// const base_url = 'https://kozpark.herokuapp.com'
const NewsCarousel = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [index, setIndex] = useState(0)
  let navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(
          `${base_url}/api/hirek?sort=publishedAt%3Adesc&pagination[pageSize]=3&populate=*`
        )
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [])

  function handleNavigation(direction, e) {
    e.stopPropagation()
    setTimeout(function () {
      if (direction === 'backward') {
        if (index === 0) {
          setIndex(data.length - 1)
        } else {
          setIndex(index - 1)
        }
      } else if (direction === 'forward') {
        if (index === data.length - 1) {
          setIndex(0)
        } else {
          setIndex(index + 1)
        }
      }
    }, 300)

    let kep = document.getElementById('hir-kep-tarolo')
    let cim = document.getElementById('hir-cim')
    let tartalom = document.getElementById('hir-tartalom')
    let datum = document.getElementById('hir-datum')

    if (kep && cim && tartalom && datum) {
      kep.style.opacity = 0
      cim.style.opacity = 0
      tartalom.style.opacity = 0
      datum.style.opacity = 0
    }
    setTimeout(function () {
      if (kep && cim && tartalom && datum) {
        kep.style.opacity = 1
        cim.style.opacity = 1
        tartalom.style.opacity = 1
        datum.style.opacity = 1
      }
    }, 500)
  }

  function arrowHover(arrow, change) {
    if (arrow === 'next') {
      let button = document.getElementById('jobb-nyil')
      let container = document.getElementById('jobb-kor')
      if (change === 'add') {
        button.classList.add('arrow-hover')
        container.classList.add('arrow-hover-container')
      } else {
        button.classList.remove('arrow-hover')
        container.classList.remove('arrow-hover-container')
      }
    } else {
      let button = document.getElementById('bal-nyil')
      let container = document.getElementById('bal-kor')
      if (change === 'add') {
        button.classList.add('arrow-hover')
        container.classList.add('arrow-hover-container')
      } else {
        button.classList.remove('arrow-hover')
        container.classList.remove('arrow-hover-container')
      }
    }
  }

  return (
    <Wrapper>
      <div className='carousel-container'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            className='hirek-fo-tarolo'
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            <h1>Hírek</h1>
            <div
              className='hir-tarolo'
              onClick={() => navigate(`/hirek/${data[index].attributes.Slug}`)}>
              <div className='hir-kep-tarolo' id='hir-kep-tarolo'>
                <img
                  src={data[index].attributes.Kep.data.attributes.url}
                  alt={
                    data[index].attributes.Kep.data.attributes.alternativeText
                  }
                />
              </div>
              <div className='kivonat-tarolo'>
                <div className='hir-szoveg-tarolo'>
                  <h2 className='hir-cim' id='hir-cim'>
                    {data[index].attributes.Cim}
                  </h2>
                  <div className='hir-tartalom' id='hir-tartalom'>
                    {Parser(
                      `${data[index].attributes.Kivonat.substring(0, 180)}...`
                    )}
                  </div>
                  <p className='hir-datum' id='hir-datum'>
                    {new Date(
                      data[index].attributes.publishedAt
                    ).toLocaleDateString('hu-HU')}
                  </p>

                  <div className='also-sav-tarolo'>
                    <div className='korhinta-lepteto-tarolo'>
                      <div className='korhinta-tarolo'>
                        <img
                          src={circle}
                          alt='circle'
                          className='bal-kor'
                          id='bal-kor'
                          onClick={(e) => handleNavigation('backward', e)}
                          onMouseOver={() => arrowHover('prev', 'add')}
                          onMouseOut={() => arrowHover('prev', 'remove')}
                        />
                        <img
                          src={arrowLeft}
                          alt='left arrow'
                          className='bal-nyil'
                          id='bal-nyil'
                          onClick={(e) => handleNavigation('backward', e)}
                          onMouseOver={() => arrowHover('prev', 'add')}
                          onMouseOut={() => arrowHover('prev', 'remove')}
                        />
                      </div>
                      <div className='szamolo-tarolo'>
                        <p className='aktualis-oldal'>
                          {index + 1}&nbsp;&nbsp;
                        </p>
                        <p className='osszes-oldal'>/ {data.length}</p>
                      </div>
                      <div>
                        <img
                          src={circle}
                          alt='circle'
                          className='jobb-kor'
                          id='jobb-kor'
                          onClick={(e) => handleNavigation('forward', e)}
                          onMouseOver={() => arrowHover('next', 'add')}
                          onMouseOut={() => arrowHover('next', 'remove')}
                        />
                        <img
                          src={arrowRight}
                          alt='right arrow'
                          className='jobb-nyil'
                          id='jobb-nyil'
                          onClick={(e) => handleNavigation('forward', e)}
                          onMouseOver={() => arrowHover('next', 'add')}
                          onMouseOut={() => arrowHover('next', 'remove')}
                        />
                      </div>
                    </div>
                    <div className='tovabbi-hirek-tarolo'>
                      <button
                        className='btn btn-left tovabbi-hirek-gomb'
                        onClick={(e) => {
                          e.stopPropagation()
                          navigate('/hirek')
                        }}>
                        További cikkeink
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;

  .loading {
    min-height: 100vh;
  }
  .carousel-container {
    width: 64%;
  }
  .hirek-fo-tarolo {
    max-width: 1232px;
    margin-top: 100px;

    h1 {
      color: var(--secondary-bg);
      font-weight: 400;
      font-style: italic;
    }
  }
  .kivonat-tarolo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 58%;
  }
  .progress-container {
    display: flex;
    flex-direction: row;
  }
  .hir-tarolo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3%;
    margin-bottom: 7%;
    width: 100%;
  }
  .hir-tarolo:hover {
    border-radius: 35px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
  }
  .hir-kep-tarolo {
    width: 42%;
    display: flex;
    justify-content: center;
    transition: all 0.3s;
  }
  .hir-kep-tarolo img {
    width: 100%;
    height: 30em;
    object-fit: cover;
    border-radius: 15px;
  }
  .hir-szoveg-tarolo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    padding-left: 7%;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-right: 3%;
    h2 {
      font-size: calc(var(--main-title-font-size) - 1rem);
      line-height: calc(var(--main-title-font-size) - 0.4rem);
    }
    .hir-tartalom {
      margin-top: 3%;
      margin-bottom: 3%;

      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      transition: all 0.3s;
    }
    .hir-cim {
      transition: all 0.3s;
    }
    .hir-tartalom p {
      font-size: var(--paragraph-font-size);
    }
    .hir-datum {
      font-size: var(--small-text-font-size);
      color: #989898;
      transition: all 0.3s;
    }
  }
  .tovabbi-hirek-betoltese-tarolo {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .tovabbi-hirek-betoltese {
    background-color: var(--box-color);
    width: 30%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: var(--button-font-size);
    color: var(--text-color);
    font-weight: 700;
    transition: all 0.3s;
  }
  .tovabbi-hirek-betoltese:hover {
    cursor: pointer;
    background-color: var(--box-color-hover);
  }
  .tovabbi-hirek-betoltese-tarolo p {
    font-size: var(--title-font-size);
  }
  .korhinta-lepteto-tarolo {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    min-width: 15rem;
  }
  .korhinta-tarolo {
    margin-left: -15px;
  }
  .szamolo-tarolo {
    width: 3.2rem;
    display: flex;
    justify-content: center;
  }
  .szamolo-tarolo .aktualis-oldal {
    font-size: var(--paragraph-font-size);
    color: var(--text-color);
    font-weight: 700;
  }
  .szamolo-tarolo .osszes-oldal {
    font-size: var(--paragraph-font-size);
    color: var(--secondary-bg);
    font-weight: 400;
  }
  .bal-nyil {
    transform: translateX(-30px) translateY(-20px);
  }
  .jobb-nyil {
    transform: translateX(-30px) translateY(-20px);
  }
  .bal-kor {
    transform: translateX(15px);
  }
  .jobb-kor {
    transform: translateX(15px);
  }
  .arrow-hover {
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(19%) sepia(6%) saturate(137%)
      hue-rotate(22deg) brightness(96%) contrast(85%);
  }
  .arrow-hover-container {
    cursor: pointer;
  }
  .tovabbi-hirek-tarolo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  .tovabbi-hirek-gomb {
    margin-top: 0 !important;
    width: 100% !important;
    padding: 0.5rem 0;
    white-space: nowrap;
    font-family: 'Poppins';
  }
  .tovabbi-hirek-gomb:hover {
    cursor: pointer;
  }
  .also-sav-tarolo {
    margin-top: 4%;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1025px) and (max-width: 1550px) {
    .hir-kep-tarolo img {
      height: 25rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .hirek-fo-tarolo {
      margin-top: 3rem;

      h1 {
        margin-left: 3%;
        font-size: calc(var(--small-text-font-size) + 0.5rem);
      }
    }
    .carousel-container {
      width: 94%;
    }

    .hir-tarolo {
      flex-direction: column;

      .hir-szoveg-tarolo {
        padding-left: 3%;
      }

      .hir-kep-tarolo img {
        height: 20rem;
      }

      .hir-kep-tarolo,
      .kivonat-tarolo {
        width: 100%;
        margin: auto;
      }

      .also-sav-tarolo {
        flex-direction: column;
        justify-content: center;

        .korhinta-lepteto-tarolo {
          width: 100%;
          justify-content: center;
          padding-top: 1rem;
        }

        .tovabbi-hirek-tarolo {
          width: 100%;
          justify-content: center;
          padding-top: 1rem;
        }
      }
    }
  }
`

export default NewsCarousel
