import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tree from "../assets/images/tree.svg";

const Error = () => {
  return (
    <Wrapper>
      <div className="error-fo-tarolo">
        <div className="content-wrapper">
          <div className="text-content">
            <h1 className="main-text">Valami nem stimmel</h1>
            <Link to="/" className="btn">
              Irány a főoldal
            </Link>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  .error-fo-tarolo {
    background-image: url(${tree});
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: center;
    height: calc(100vh - 250px);
    width: 100%;

    @media screen and (max-width: 1024px) {
      background-position-x: center;
      background-position-y: bottom;
      height: calc(100vh - 250px);
      width: 100%;
      background-size: 160px;
    }

    .content-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .main-text {
      font-size: var(--main-title-font-size);
      line-height: 1.2;
      width: 80%;
      margin: auto;
      padding-bottom: 2rem;
    }

    .text-content {
      @media screen and (max-width: 1024px) {
        height: 100%;
        margin-top: 25%;
      }
    }
    .btn {
      border-radius: 5px;
      border-width: 0;
      color: var(--text-color);
      background-color: var(--box-color);
      font-size: var(--button-font-size);
      width: 50%;
      text-decoration: none;
      text-align: center;
      transition: all 0.3s ease-in-out;
      border-radius: 50px;
      padding: 1rem 3rem;
    }
    .btn:hover {
      color: var(--text-color);
      background-color: var(--box-color-hover);
    }

    .bg-image {
      padding-right: -15%;
    }
    @media (max-width: 1024px) {
      .content-wrapper {
        flex-direction: column;
      }
    }
  }
`;

export default Error;
