import React, { useState, useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'
import { base_url } from '../helper/urls'
import { motion } from 'framer-motion'
import Parser from 'html-react-parser'

// const base_url = 'https://kozpark.herokuapp.com'

const KozerdekuAdat = () => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { slug } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        let urlSlug = ''
        if (slug === 'szervezeti') {
          urlSlug = 'szervezeti-szemelyzeti-adatok'
        } else if (slug === 'tevekenyseg') {
          urlSlug = 'tevekenysegre-mukoedesre-vonatkozo-adatok'
        } else if (slug === 'koltsegvetes-beszamolok') {
          urlSlug = 'koltsegvetesek-beszamolok'
        } else if (slug === 'kozbeszerzes') {
          urlSlug = 'kozbeszerzesek'
        }
        console.log(base_url + '/api/' + urlSlug + '?populate=*')
        const response = await axios.get(
          `${base_url}/api/${urlSlug}?populate=*`
        )
        console.log(response.data.data)
        setData(response.data.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchData()
    //eslint-disable-next-line
  }, [])

  const title = () => {
    if (slug === 'szervezeti') {
      return (
        <h1 className='kozerdeku-adat-focim'>
          I. Szervezeti, személyzeti adatok
        </h1>
      )
    } else if (slug === 'tevekenyseg') {
      return (
        <h1 className='kozerdeku-adat-focim'>
          II. Tevékenységre, működésre vonatkozó addatok
        </h1>
      )
    } else if (slug === 'koltsegvetes-beszamolok') {
      return <h1 className='kozerdeku-adat-focim'>Költségvetés, Beszámolók</h1>
    } else if (slug === 'kozbeszerzes') {
      return <h1 className='kozerdeku-adat-focim'>Közbeszerzés</h1>
    }
  }

  return (
    <Wrapper>
      <div className='kozerdeku-adatok-fo-tarolo'>
        {isLoading ? (
          <div className='loading'></div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.3,
            }}>
            <div className='kozerdeku-adatok-tarolo'>
              {title()}
              {data.map(function (item, i) {
                return (
                  <div className='kozerdeku-adat-tarolo' key={i}>
                    <h2 className='kozerdeku-adat-cim'>
                      {Parser(item.attributes.Cim)}
                    </h2>

                    <a
                      className='kozerdeku-adat-letoltes-tarolo'
                      href={item.attributes.PDF.data.attributes.url}
                      target='_blank'
                      rel='noreferrer'>
                      <div className='kozerdeku-adat-letoltes'>Megtekintés</div>
                    </a>
                  </div>
                )
              })}
            </div>
            <div className='vissza-a-kozerdeku-adatokhoz-fo-tarolo'>
              <NavLink
                className='vissza-a-kozerdeku-adatokhoz-tarolo'
                to='/kozerdeku-adatok'>
                <div className='vissza-a-kozerdeku-adatokhoz-gomb'>
                  Vissza az adatokhoz
                </div>
              </NavLink>
            </div>
          </motion.div>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  background-color: var(--light-grey);
  .kozerdeku-adatok-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    margin-top: 250px;
    .loading {
      min-height: 100vh;
    }
    .kozerdeku-adat-focim,
    .palyazataink-focim {
      margin-bottom: 2%;
      font-size: var(--main-title-font-size);
      font-weight: 800;
    }
    .kozerdeku-adatok-tarolo {
      margin-bottom: 7%;
    }
    .kozerdeku-adat-tarolo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-radius: 35px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding-left: 8.44%;
      padding-right: 8.44%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin-bottom: 1%;
      background-color: var(--primary-bg);
    }
    .kozerdeku-adat-cim {
      width: 70%;
      font-size: var(--title-font-size);
      font-weight: 800;
    }
    .kozerdeku-adat-letoltes-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      transition: all 0.3s;
    }
    .kozerdeku-adat-letoltes-tarolo:hover {
      background-color: var(--box-color-hover);
    }
    .kozerdeku-adat-letoltes {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
  }

  .vissza-a-kozerdeku-adatokhoz-tarolo {
    background-color: var(--box-color);
    width: 40%;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    padding-top: 2%;
    padding-bottom: 2%;
    transition: all 0.3s;
  }
  .vissza-a-kozerdeku-adatokhoz-tarolo:hover {
    background-color: var(--box-color-hover);
  }
  .vissza-a-kozerdeku-adatokhoz-gomb {
    font-size: var(--button-font-size);
    color: var(--text-color);
    font-weight: 700;
  }
  .text-small {
    font-size: var(--small-text-font-size);
  }
  .vissza-a-kozerdeku-adatokhoz-fo-tarolo {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    .kozerdeku-adatok-fo-tarolo {
      width: 94%;
      margin-top: 2.5rem;

      .kozerdeku-adat-focim,
      .palyazataink-focim {
        font-size: var(--title-font-size);
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .kozerdeku-adat-tarolo {
        flex-direction: column;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        .kozerdeku-adat-cim {
          font-size: var(--small-text-font-size);
          width: 100%;
          text-align: center;
        }

        .kozerdeku-adat-letoltes-tarolo {
          width: 80%;
          margin-top: 1rem;
          padding: 1rem 0;
        }
      }
    }

    .vissza-a-kozerdeku-adatokhoz-tarolo {
      width: 100%;
      margin: 3rem 0;
      padding: 1rem 0;
    }
  }
`

export default KozerdekuAdat
