import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'
import React, { useState, useContext } from 'react'
import pos from '../assets/position-logo.svg'
import mail from '../assets/mail-logo.svg'
import phone from '../assets/phone-logo.svg'
import logo from '../assets/logo.svg'
import { GlobalContext } from '../helper/GlobalContext'

const Navbar = () => {
  const [clicked, setClicked] = useState(false)
  const { setNavClicked, setAnimationCanStart } = useContext(GlobalContext)

  function navClickHandle() {
    setAnimationCanStart(false)
    setNavClicked(true)
  }

  return (
    <Wrapper id='nav'>
      <div className='header-top'>
        <ul className='header-top__left'>
          <li>
            <img src={pos} alt='Position' />
            1192 Budapest, Bercsényi utca 18.
          </li>
          <li>
            <img src={mail} alt='Mail' />
            <a href='mailto:info@kozpark.hu'>info@kozpark.hu</a>
          </li>
          <li>
            <img src={phone} alt='Telephone' />
            <a href='phone:+3612829622'>+36 1 282 9622</a>
          </li>
        </ul>
        <ul className='header-top__right'>
          <li>
            <span>Nyitvatartás:&nbsp;</span>H-Cs: 7:30-15:15, P: 7:30-12:30
          </li>
        </ul>
      </div>
      <div className='desktop-menu-wrapper'>
        <NavLink to='/' aria-label='back to home btn'>
          <li onClick={navClickHandle}>
            <img src={logo} alt='Kozpark logo' />
          </li>
        </NavLink>
        <ul className='desktop-menu'>
          <div className='dropdown'>
            <li className='dropbtn' activeclassname='active'>
              Feladataink
            </li>
            <div className='dropdown-content'>
              <a href='/szolgaltatasok/parkfenntartas'>Parkfenntartás</a>
              <a href='/szolgaltatasok/koztisztasag'>Köztisztaság</a>
              <a href='/szolgaltatasok/varosuzemeltetes'>Városüzemeltetés</a>
              <a href='/szolgaltatasok/zoldteruletfejlesztes'>
                Kiemelt zöldfelületfejlesztés
              </a>
              <a href='/szolgaltatasok/szakipar'>Szakipar</a>
              <a href='/szolgaltatasok/szolgaltatas'>Szolgáltatás</a>
            </div>
          </div>
          <NavLink to='/rolunk' activeclassname='active'>
            <li onClick={navClickHandle}>Rólunk</li>
          </NavLink>
          <NavLink to='/hirek' activeclassname='active'>
            <li onClick={navClickHandle}>Hírek</li>
          </NavLink>
          <NavLink to='/kapcsolat' activeclassname='active'>
            <li onClick={navClickHandle}>Kapcsolat</li>
          </NavLink>
          <NavLink to='/felhivasok' activeclassname='active'>
            <li onClick={navClickHandle}>Pályázatok</li>
          </NavLink>
        </ul>
      </div>

      <div className='mobile-wrapper'>
        <NavLink to='/'>
          <li onClick={navClickHandle}>
            <img src={logo} alt='Kozpark logo' />
          </li>
        </NavLink>
        <div className='mobile-nav'>
          <div className='hamburger-ul' onClick={() => setClicked(!clicked)}>
            <div className={`hamburger-1 ${clicked ? 'close-1' : ''}`}></div>
            <div className={`hamburger-2 ${clicked ? 'close-2' : ''}`}></div>
            <div className={`hamburger-3 ${clicked ? 'close-3' : ''}`}></div>
          </div>
          <ul className={`mobile-menu ${clicked ? 'active' : ''}`}>
            <div className='dropdown'>
              <li className='dropbtn mobile-menuitem' activeclassname='active'>
                Feladataink
              </li>
              <div className='dropdown-content'>
                <a href='/szolgaltatasok/parkfenntartas'>Parkfenntartás</a>
                <a href='/szolgaltatasok/koztisztasag'>Köztisztaság</a>
                <a href='/szolgaltatasok/varosuzemeltetes'>Városüzemeltetés</a>
                <a href='/szolgaltatasok/zoldteruletfejlesztes'>
                  Kiemelt zöldfelületfejlesztés
                </a>
                <a href='/szolgaltatasok/szakipar'>Szakipar</a>
                <a href='/szolgaltatasok/szolgaltatas'>Szolgáltatás</a>
              </div>
            </div>
            <Link to='/rolunk'>
              <li className='mobile-menuitem' onClick={() => setClicked(false)}>
                Rólunk
              </li>
            </Link>
            <Link to='/hirek'>
              <li className='mobile-menuitem' onClick={() => setClicked(false)}>
                Hírek
              </li>
            </Link>
            <Link to='/kapcsolat'>
              <li className='mobile-menuitem' onClick={() => setClicked(false)}>
                Kapcsolat
              </li>
            </Link>
            <Link to='/felhivasok'>
              <li className='mobile-menuitem' onClick={() => setClicked(false)}>
                Pályázatok
              </li>
            </Link>
            <hr />

            <ul className='header-bottom'>
              <li>
                <img src={pos} alt='Position' />
                1192 Budapest, Bercsényi utca 18.
              </li>
              <li>
                <img src={mail} alt='Mail' />
                <a href='mailto:info@kozpark.hu'>info@kozpark.hu</a>
              </li>
              <li>
                <img src={phone} alt='Telephone' />
                <a href='phone:+3612829622'>+36 1 282 9622</a>
              </li>
              <li className='nyitva'>
                <p>
                  <span>Nyitvatartás:</span>
                </p>
                <p>H-Cs: 7:30-15:15,</p>
                <p>P: 7:30-12:15</p>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  .header-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    inset: 0 0 0 0;
    width: 100%;
    height: 50px;
    padding-inline: 18%;
    background-color: var(--box-color);
    font-size: 0.8rem;

    .header-top__left,
    .header-top__right {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style: none;
      color: var(--black);

      a {
        color: var(--black);
      }
    }

    img {
      padding-right: 0.5rem;
    }
    span {
      font-weight: 700;
    }
  }

  .desktop-menu-wrapper {
    display: flex;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100px;
    flex-direction: row;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    padding-inline: 18%;
    font-size: var(--paragraph-font-size);
    gap: 2rem;
    color: var(--text-color);
    background-color: var(--main-bg);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .desktop-menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-size: 1rem;

    .dropdown {
      position: relative;
      display: flex;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      top: 0;
      left: -12px;
      background-color: var(--primary-bg);
      width: 220px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      border-radius: 0 0 15px 15px;
      z-index: -1;
      transition: all 0.5s ease-in-out;
    }

    .dropdown-content a:first-child {
      padding-top: 58px;
    }

    .dropdown-content a {
      color: var(--text-color);
      padding: 12px 16px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.15s ease;
    }

    .dropdown-content a:hover {
      font-weight: 700;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    li {
      cursor: pointer;
      text-decoration: none;
      list-style: none;
      position: relative;
      transition: all 0.3s ease;
    }

    .active {
      position: relative;
      text-shadow: 0px 0px 1px #000;
    }

    a {
      display: flex;
      justify-content: center;
    }

    li:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--box-color);
      color: var(--secondary-bg);
    }
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: 1024px) {
    .mobile-wrapper {
      display: flex;
      width: 100%;
      height: 100px;
      align-items: center;
      justify-content: space-between;
      padding-inline: 10%;
      background: var(--primary-bg);
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      li {
        list-style: none;
      }
    }

    .header-top {
      display: none;
    }

    .hamburger-ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-evenly;
      width: 22px;
      height: 25px;
      cursor: pointer;
    }

    .hamburger-1,
    .hamburger-2,
    .hamburger-3 {
      width: 20px;
      height: 3px;
      background-color: var(--text-color);
      transition: all 0.3s ease-in-out;
    }

    .close-1 {
      transform: rotate(45deg) translateY(6px);
    }
    .close-3 {
      transform: rotate(-45deg) translateY(-6px);
    }
    .close-2 {
      display: none;
    }

    .mobile-menu {
      position: absolute;
      top: 100px;
      right: 0;
      display: flex;
      flex-direction: column;
      background-color: var(--box-color);
      width: 80%;
      height: calc(100vh - 100px);
      align-items: left;
      justify-content: space-evenly;
      transform: translateX(100%);
      transition: all 0.3s ease-in-out;
      padding: 2rem 8%;
      list-style: none;
    }

    hr {
      margin: 15% 10% 8%;
      border-radius: none;
      height: 2px;
      width: 100%;
      margin-inline: auto;
      background-color: white;
    }

    .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      height: 35%;
      background-color: var(--box-color);

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        color: black;

        a {
          color: black;
        }
      }

      li p {
        color: black;
      }

      .nyitva {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      img {
        padding-right: 0.5rem;
      }
      span {
        font-weight: 700;
      }
    }

    .active {
      transform: translateX(0);
    }

    .mobile-menuitem {
      font-weight: 300;
      position: relative;
      width: fit-content;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      text-decoration: none;
      color: black;
    }

    .mobile-menuitem:hover::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: var(--box-color);
      color: var(--black);
      cursor: pointer;
    }

    .dropdown {
      position: relative;
      display: flex;
    }

    .dropdown-content {
      display: none;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      top: 48px;
      left: 0;
      background-color: var(--box-color);
      width: 100%;
      z-index: 1;
      transition: all 0.5s ease-in-out;
      border-radius: 10px;
    }

    .dropdown-content a {
      color: var(--text-color);
      text-decoration: none;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      transition: all 0.15s ease;
    }

    .dropdown-content a:hover {
      font-weight: 700;
    }

    .dropdown:hover .dropdown-content {
      display: flex;
    }
  }
`

export default Navbar
