import React from 'react'
import styled from 'styled-components'
import netlifyLogo from '../assets/netlify-logo.png'
import logo from '../assets/logo.svg'

const Impressum = () => {
  return (
    <Wrapper>
      <div className='content-wrapper'>
        <div className='main-title'>
          <h1>Impresszum</h1>
          <hr />
        </div>
        <div>
          <h2>Tárhelyszolgáltató:</h2>
          <h3>Netlify Inc.</h3>
          <picture>
            <img className='logo' src={netlifyLogo} alt='Netlify logo' />
          </picture>
          <div>
            <h3>Székhely:</h3>
            <p>
              44 Montgomery Street, Suite 300, San Francisco, California 94104.
            </p>
            <h3>Levelezési cím:</h3>
            <p>
              44 Montgomery Street, Suite 300, San Francisco, California 94104.
            </p>
            <h3>Email:</h3>
            <p>support@netlify.com</p>
          </div>
        </div>

        <div>
          <h2>Weboldal üzemeltetője:</h2>
          <h3>
            KÖZPARK Kispesti Köztisztasági és Közfoglalkoztatási Nonprofit Kft.
          </h3>
          <picture>
            <img src={logo} alt='Netlify logo' />
          </picture>
          <div>
            <h3>Székhely / levelezési cím:</h3>
            <p>1192 Budapest, Bercsényi utca 18.</p>
            <h3>Nyilvántartásba bejegyző hatóság:</h3>
            <p>
              Belügyminisztérium Nyilvántartások Vezetéséért Felelős Helyettes
              Államtitkár Okmányfelügyeleti Főosztály
            </p>
            <h3>Adószám:</h3>
            <p>20604149243</p>
            <h3>Cégjegyzékszám:</h3>
            <p>01 09 897400</p>
            <h3>Telefonszám:</h3>
            <p>
              A legtöbb online szolgáltatáshoz hasonlóan weboldalunk is több
              célból belső és harmadik féltől származó sütiket használ. A belső
              cookie-k többnyire szükségesek a webhely megfelelő működéséhez, és
              nem gyűjtik az Ön személyazonosításra alkalmas adatait.
            </p>
            <h3>E-mail cím:</h3>
            <p>kozpark@kispest.hu</p>
            <h3>Iparkamara:</h3>
            <p>Pest Megyei Kereskedelmi és Iparkamara</p>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  .content-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 250px;
    width: 64%;

    img {
      margin-bottom: 1rem;
    }

    h2 {
      font-size: var(--title-font-size);
      margin-bottom: 1rem;
    }

    h3 {
      font-size: calc(var(--paragraph-font-size) + 0.5rem);
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    p,
    hr {
      margin-bottom: 2rem;
    }

    .main-title h1 {
      font-size: var(--main-title-font-size);
    }

    img {
      width: 15%;
      padding-block: 1rem;
    }

    img.logo {
      width: 10%;
    }
  }

  @media screen and (max-width: 1024px) {
    .content-wrapper {
      width: 94%;
      margin-top: 5rem;

      img {
        width: 40%;
      }
      img.logo {
        width: 20%;
      }
    }
  }
`

export default Impressum
